<template>
  <el-form :model="addForm" :form="addForm" ref="form" class="addForm" size="small" label-position="top" :rules="rules">
    <el-form-item label="项目或工地：" prop="xiangmuorgongdi">
      <el-input placeholder="请选择库房" v-model="addForm.xiangmuorgongdi"></el-input>
      <!--            <el-select>              &lt;!&ndash;点击之后弹出选项，不需要就换&ndash;&gt;-->
      <!--                <el-option-->
      <!--                        v-for="item in xiangmuorgongdi"-->
      <!--                        :key="item.value"-->
      <!--                        :label="item.label"-->
      <!--                        :value="item.value"-->
      <!--                ></el-option>-->
      <!--            </el-select>-->
    </el-form-item>
    <el-form-item label="合约备注">
      <el-input v-model="addForm.remark" type="textarea"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    addForm: Object
  },
  data() {
    return {
      label_width: '120px',
      isValidationOk: false,
      xiangmuorgongdi: [
        {
          value: '5',
          label: 'SY2020-002#大连甘井子'
        },
        {
          value: '6',
          label: 'SY2020-003#蒲河收费站旁'
        },
        {
          value: '7',
          label: 'SY2020-010#辽滨'
        }
      ],
      rules: {
        xiangmuorgongdi: [{ required: true, message: '请输入项目或工地名称', trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    }
  }
}
</script>

<style scoped></style>
